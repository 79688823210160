<template>
  <Dropdown :text="title">
    <DropdownItem
      v-for="participant in participants"
      :key="participant.id"
      @click="select(participant)">
      {{ participant.name }}
    </DropdownItem>

    <DropdownItem v-if="participants.length === 0">
      {{ $t('report.participants.none') }}
    </DropdownItem>

    <DropdownDivider />

    <DropdownItem @click="select(null)">
      {{ $t('report.participants.unspecified') }}
    </DropdownItem>
  </Dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      participants: 'report/participants/getAll'
    }),

    title () {
      return this.event.reportedBy !== null ? this.event.reportedBy.name : this.$i18n.t('report.participants.select')
    }
  },

  methods: {
    select (participant) {
      this.event.reportedBy = participant
    }
  }
}
</script>
