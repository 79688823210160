<template>
  <MapMarker
    :location="coordinate"
    :icon="icon">
  </MapMarker>
</template>

<script>
import MapMarker from '@/modules/map/components/MapMarker.vue'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  components: {
    MapMarker
  },

  props: {
    coordinate: {
      type: Object,
      required: true
    },

    type: {
      type: String,
      required: true
    }
  },

  computed: {
    icon () {
      return getMarkerTypeIconUrl(this.type)
    }
  }
}
</script>
