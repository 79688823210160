<template>
  <MapMarker
    :name="marker.name"
    :icon="icon"
    :location="marker.location"
    :clickable="clickable"
    :opacity="opacity"
    @click="onClick">
  </MapMarker>
</template>

<script>
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'
import MapMarker from '@/modules/map/components/MapMarker.vue'

export default {
  components: {
    MapMarker
  },

  props: {
    marker: {
      type: Object,
      required: true
    },

    event: {
      type: Object,
      required: true
    }
  },

  computed: {
    isSelected () {
      return this.marker.id === this.event.poiId
    },

    icon () {
      return this.isSelected ? getMarkerTypeIconUrl(this.event.type) : getMarkerTypeIconUrl(this.marker.type)
    },

    isPassOrTower () {
      return this.marker.type === 'pass' || this.marker.type === 'tower'
    },

    opacity () {
      return this.isPassOrTower ? 1 : 0.5
    },

    clickable () {
      return this.isPassOrTower
    }
  },

  methods: {
    onClick () {
      this.$emit('click', this.marker)
    }
  }
}
</script>
