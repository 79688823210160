<template>
  <Form class="push-bottom" @submit="onSubmit">
    <FormField :control="false">
      <div class="control has-icons-left" :class="{ 'has-icons-right': nameOrEmail.length > 0 }">
        <Icon name="icon-search" class="is-left" />

        <TextInputField
          v-model="nameOrEmail"
          :placeholder="$t('user.searchUserPlaceholder')"
          @input="onInput">
        </TextInputField>

        <Icon
          v-if="nameOrEmail.length > 0"
          name="icon-x"
          class="is-right reset-search"
          @click="resetSearch">
        </Icon>
      </div>
    </FormField>

    <div v-if="users !== null">
      <Alert
        v-if="noUsersFound"
        type="info"
        :message="notFoundText">
      </Alert>

      <Paragraph v-else>
        <small>
          {{ $tc('user.searchUsersMatching', users.length, { userCount: users.length }) }}
        </small>
      </Paragraph>
    </div>
  </Form>
</template>

<script>
import UserModel from '../models/userModel'

export default {
  props: {
    notFoundSelectable: {
      type: Boolean,
      default: true
    },

    notFoundText: {
      type: String,
      default: ''
    },

    selected: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      nameOrEmail: '',
      users: null,
      loading: false,
      searchTimeout: null
    }
  },

  computed: {
    isEmailSearch () {
      return this.nameOrEmail.indexOf('@') !== -1 && this.validateEmail()
    },

    noUsersFound () {
      return this.users.length === 1 && this.users[0].id === null
    }
  },

  methods: {
    async onSubmit () {
      try {
        this.cancelSearchTimeout()

        this.users = null

        if (this.nameOrEmail.length === 0) {
          this.resetSearch()
        } else {
          this.$emit('search-started')

          let users = []
          this.loading = true

          if (this.isEmailSearch) {
            users = await this.$store.dispatch('user/getByEmail', this.nameOrEmail)
            if (users.length === 0 && this.notFoundSelectable) {
              users.push(new UserModel({
                email: this.nameOrEmail
              }))
            }
          } else {
            const name = this.nameOrEmail.trim()

            if (this.validateName(name)) {
              users = await this.$store.dispatch('user/getByName', name)
            } else {
              users = []
            }
          }

          this.users = users.filter(this.filterVisibleUsers)

          this.$emit('search-complete', users)
          this.loading = false
        }
      } catch (error) {
        console.error(error)
      }
    },

    onInput () {
      if (this.nameOrEmail.length === 0) {
        this.resetSearch()
      } else {
        this.startSearchTimeout()
      }
    },

    validateEmail () {
      // eslint-disable-next-line
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.nameOrEmail)
    },

    validateName (name) {
      const parts = name.split(' ')
      return parts.length >= 2
    },

    resetSearch () {
      this.nameOrEmail = ''
      this.users = null
      this.$emit('search-reset')
    },

    startSearchTimeout () {
      this.cancelSearchTimeout()

      this.searchTimeout = setTimeout(() => {
        this.onSubmit()
      }, 1000)
    },

    cancelSearchTimeout () {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout)
        this.searchTimeout = null
      }
    },

    filterVisibleUsers (user) {
      const i = this.selected.findIndex(selectedUser => user.id !== null ? user.id === selectedUser.id : user.email === selectedUser.email)
      return i === -1
    }
  }
}
</script>

<style lang="scss" scoped>
input::-ms-clear {
  display: none;
}

.reset-search {
  pointer-events: all !important;
  cursor: pointer !important;

  .icon {
    color: black !important;
  }
}
</style>
