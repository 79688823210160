<template>
  <TableRow>
    <TableCell :class="classList">
      {{ game.animalName }}

      <Icon
        v-if="reportedToViltrapportMismatch"
        name="icon-alert-triangle">
      </Icon>
    </TableCell>

    <TableCell>
      <NumberInputField
        v-model="game.count"
        :valid="valid"
        :min="1">
      </NumberInputField>
    </TableCell>

    <TableCell class="is-narrow">
      <Icon
        name="icon-minus-circle"
        class="has-text-danger is-clickable"
        @click="onRemove">
      </Icon>
    </TableCell>
  </TableRow>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true
    },

    game: {
      type: Object,
      required: true
    }
  },

  computed: {
    gameCount () {
      return this.game.count
    },

    reportedToViltrapportMismatch () {
      return this.game.count < this.game.viltrapportReports.length
    },

    classList () {
      return {
        'has-text-danger': this.reportedToViltrapportMismatch
      }
    },

    valid () {
      return this.game.invalid ? 'invalid' : null
    }
  },

  watch: {
    gameCount () {
      this.game.invalid = false
      this.$emit('game-count-changed', this.game)
    }
  },

  methods: {
    onRemove () {
      this.event.removeGame(this.game)
      this.$emit('remove-game', this.game)
    }
  }
}
</script>
