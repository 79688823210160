<template>
  <InlineLoader v-if="users === null" />

  <div v-else>
    <UserSearchDialogListItem
      v-for="user in users"
      :key="user.id"
      :user="user"
      @click="onUserClicked(user)">
    </UserSearchDialogListItem>
  </div>
</template>

<script>
import UserSearchDialogListItem from './UserSearchDialogListItem.vue'

export default {
  components: {
    UserSearchDialogListItem
  },

  props: {
    users: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    onUserClicked (user) {
      this.$emit('user-clicked', user)
    }
  }
}
</script>
