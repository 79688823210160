<template>
  <div class="report-event-dialog-accordion">
    <div class="level is-mobile is-clickable" @click="toggleExpanded">
      <div class="level-left">
        <div class="report-event-dialog-accordion-title">{{ title }}</div>
      </div>

      <div class="level-right">
        <div v-if="subtitle.length > 0" class="level-item">
          {{ subtitle }}
        </div>

        <div class="level-item has-text-primary">
          <Icon :name="chevronIcon" />
        </div>
      </div>
    </div>

    <div v-if="expanded" class="report-event-dialog-accordion-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      expanded: false
    }
  },

  computed: {
    chevronIcon () {
      return this.expanded ? 'icon-chevron-up' : 'icon-chevron-down'
    }
  },

  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
.report-event-dialog-accordion {
  margin-bottom: 1rem;

  .report-event-dialog-accordion-title {
    font-weight: bold;
  }

  .report-event-dialog-accordion-content {
    margin-top: 1rem;
  }
}

.report-event-dialog-accordion .level {
  margin-bottom: 0 !important;
}
</style>
