<template>
  <Alert type="danger">
    <Paragraph :text="$t('report.gameRemovedMessage', { gameName: game.animalName })" />

    <Button
      v-if="canUndoRemoveGame"
      size="small"
      :title="$t('general.undo')"
      @click="undoRemoveGame">
    </Button>
  </Alert>
</template>

<script>
export default {
  props: {
    game: {
      type: Object,
      required: true
    },

    removedGames: {
      type: Array,
      required: true
    }
  },

  computed: {
    canUndoRemoveGame () {
      return this.removedGames.indexOf(this.game) !== -1
    }
  },

  methods: {
    undoRemoveGame () {
      this.$emit('undo-remove-game', this.game)
    }
  }
}
</script>
