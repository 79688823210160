<template>
  <div v-if="hasLocation">
    <div v-if="event.poiId" class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <img :src="icon" />
        </div>

        <div class="level-item">
          {{ event.poiName }}
        </div>

        <div class="level-item">
          <Icon
            class="is-clickable"
            name="icon-x"
            @click="onClearClicked">
          </Icon>
        </div>
      </div>
    </div>

    <div v-else>
      {{ coordinate.lat }}, {{ coordinate.lng }}

      <Icon
        class="is-clickable"
        name="icon-x"
        @click="onClearClicked">
      </Icon>
    </div>
  </div>

  <Paragraph v-else :text="$t('report.locationHelpText')" />
</template>

<script>
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasLocation () {
      return this.event.poiId !== null || this.event.coordinate !== null
    },

    icon () {
      return getMarkerTypeIconUrl(this.event.poiType)
    },

    coordinate () {
      return {
        lat: this.event.coordinate.lat.toFixed(4),
        lng: this.event.coordinate.lng.toFixed(4)
      }
    }
  },

  methods: {
    onClearClicked () {
      this.event.poiId = null
      this.event.poiName = null
      this.event.poiType = null
      this.event.coordinate = null
    }
  }
}
</script>
