<template>
  <div class="level is-mobile user" @click="onClick">
    <div class="level-left">
      <div class="level-item">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <ProfilePicture :userId="Number(user.id)" size="small" round />
            </div>

            <div class="level-item">
              <div>
                <p class="user-name">{{ user.getFullName() }}</p>
                <small v-if="user.email.length > 0">{{ user.email }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="level-right">
      <div class="level-item">
        <input v-model="user.selected" type="checkbox" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.user {
  padding: 10px;
  cursor: pointer;
}

.user:hover {
  background-color: rgb(245, 245, 245);
}

.user .user-name {
  font-weight: 600;
  margin-bottom: 0;
}

.level {
  margin-bottom: 0;
}
</style>
