<template>
  <Dialog
    :title="$t('report.removeReport.title')"
    :buttons="buttons"
    :close-enabled="!removing"
    @close="cancel">
    <Paragraph :text="message" />
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

export default {
  components: {
    Dialog
  },

  props: {
    sentToViltrapport: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      removing: false
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      events: 'report/events/getAll'
    }),

    buttons () {
      return [
        {
          title: this.$t('general.cancel'),
          disabled: this.removing,
          click: this.cancel
        },
        {
          title: this.$t('general.delete'),
          type: 'primary',
          loading: this.removing,
          click: this.remove
        }
      ]
    },

    message () {
      return this.sentToViltrapport ? this.$i18n.t('report.removeReport.viltrapportMessage') : this.$i18n.t('report.removeReport.message')
    }
  },

  methods: {
    cancel () {
      this.$emit('close')
    },

    async remove () {
      try {
        this.removing = true

        await this.$store.dispatch('report/remove')

        this.$router.push({
          name: 'hunts',
          params: {
            huntAreaId: this.huntArea.id
          }
        })
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.unknownErrorMessage'))
      } finally {
        this.removing = false
      }
    }
  }
}
</script>
