<template>
  <Dialog
    :title="$t('report.removeEvent.title')"
    :buttons="buttons"
    @close="close">
    <Paragraph :text="message" />
  </Dialog>
</template>

<script>
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

export default {
  components: {
    Dialog
  },

  props: {
    event: {
      type: Object,
      required: true
    },

    sentToViltrapport: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    buttons () {
      return [
        {
          title: this.$t('general.cancel'),
          click: this.close
        },
        {
          title: this.$t('general.delete'),
          type: 'primary',
          click: this.remove
        }
      ]
    },

    message () {
      return this.sentToViltrapport ? this.$i18n.t('report.removeEvent.viltrapportMessage') : this.$i18n.t('report.removeEvent.message')
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    remove () {
      this.$store.commit('report/events/remove', this.event)
      this.close()
    }
  }
}
</script>
