<template>
  <UserSearchDialog
    :title="$t('report.participants.add')"
    :description="$t('report.participants.helpText')"
    :all-users-selected-text="$t('report.participants.allMembersIncludedInReport')"
    :confirm-button-title="confirmButtonTitle"
    :selectable="members"
    :selected="participants"
    :not-found-text="$t('report.participants.emailNotFound')"
    @selection-changed="onSelectionChanged"
    @confirm="onConfirm"
    @close="onClose">
  </UserSearchDialog>
</template>

<script>
import UserSearchDialog from '@/modules/user/components/UserSearchDialog.vue'
import UserModel from '@/modules/user/models/userModel'

export default {
  components: {
    UserSearchDialog
  },

  data () {
    return {
      selectedUsers: []
    }
  },

  computed: {
    members () {
      return this.$store.getters['huntarea/members/members']
    },

    confirmButtonTitle () {
      return this.$i18n.tc('report.participants.addButtonTitle', this.selectedUsers.length, {
        userCount: this.selectedUsers.length
      })
    },

    participants () {
      const participants = this.$store.getters['report/participants/getAll'] || []
      return participants.map(participant => {
        return new UserModel({
          id: participant.userId,
          firstName: participant.name,
          email: participant.email
        })
      })
    }
  },

  methods: {
    onClose () {
      this.close()
    },

    onConfirm (users) {
      users.forEach(user => {
        this.$store.commit('report/participants/add', user)
      })

      this.close()
    },

    onSelectionChanged (users) {
      this.selectedUsers = users
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>
