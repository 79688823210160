<template>
  <Dialog
    :title="title"
    :buttons="buttons"
    :close-enabled="!loading"
    @close="onClose">
    <Paragraph v-if="description.length > 0" :text="description" />

    <UserSearchDialogForm
      :selected="[...selected, ...currentSelection]"
      :not-found-text="notFoundText"
      :not-found-selectable="notFoundSelectable"
      @search-started="onSearchStarted"
      @search-complete="onSearchComplete"
      @search-reset="onSearchReset">
    </UserSearchDialogForm>

    <Alert
      v-if="allSelectableUsersSelected"
      type="info"
      :message="allUsersSelectedText">
    </Alert>

    <Button
      v-if="canToggleSelectAll"
      size="small"
      :title="toggleSelectallButtonTitle"
      @click="onToggleSelectAll">
    </Button>

    <UserSearchDialogList
      :users="visibleUsers"
      @user-clicked="onUserClicked">
    </UserSearchDialogList>

    <BadgeList class="push-top">
      <Badge
        v-for="user in currentSelection"
        :key="user.id"
        type="light">
        {{ user.getFullName() }}
        <Icon
          class="is-clickable"
          name="icon-x"
          @click="onUserClicked(user)">
        </Icon>
      </Badge>
    </BadgeList>
  </Dialog>
</template>

<script>
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

import UserSearchDialogForm from './UserSearchDialogForm.vue'
import UserSearchDialogList from './UserSearchDialogList.vue'

export default {
  components: {
    Dialog,
    UserSearchDialogForm,
    UserSearchDialogList
  },

  props: {
    title: {
      type: String,
      default: 'Add Member'
    },

    description: {
      type: String,
      default: ''
    },

    // Pre-populated list of selectable users
    selectable: {
      type: Array,
      default: () => []
    },

    // Users considered selected by the client
    selected: {
      type: Array,
      default: () => []
    },

    notFoundSelectable: {
      type: Boolean,
      default: true
    },

    notFoundText: {
      type: String,
      default: ''
    },

    loading: {
      type: Boolean,
      default: false
    },

    confirmButtonTitle: {
      type: String,
      default: 'Add'
    },

    allUsersSelectedText: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      users: null,
      isSearching: false,
      currentSelection: [],
      allSelectableUsersSelected: false
    }
  },

  computed: {
    buttons () {
      return [{
        title: this.$i18n.t('general.cancel'),
        disabled: this.loading,
        click: this.onClose
      }, {
        type: 'primary',
        title: this.confirmButtonTitle,
        loading: this.loading,
        disabled: this.currentSelection.length === 0,
        click: this.onConfirm
      }]
    },

    visibleUsers () {
      if (this.users !== null) {
        const users = this.users.filter(this.filterVisibleUsers)
        return users.slice().sort(this.sortUsersByName)
      }

      return null
    },

    selectableNotAlreadySelected () {
      const selectable = this.selectable || []
      return selectable.filter(this.filterVisibleUsers)
    },

    toggleSelectallButtonTitle () {
      const selected = this.selectableNotAlreadySelected.filter(user => user.selected)
      const allSelected = selected.length === this.selectableNotAlreadySelected.length

      return allSelected ? this.$i18n.t('user.deselectAll') : this.$i18n.t('user.selectAll')
    },

    canToggleSelectAll () {
      return !this.isSearching && this.selectable !== null ? this.selectable.length > 0 && !this.isAllSelectableUsersSelected() : false
    }
  },

  watch: {
    // If the selectable users is null (they are still loading),
    // we must watch the variable and set it once it's done.
    selectable () {
      this.users = this.selectable
      this.allSelectableUsersSelected = this.isAllSelectableUsersSelected()
    }
  },

  mounted () {
    if (this.selectable !== null) {
      this.selectable.forEach(user => {
        user.selected = false
      })

      this.users = this.selectable
      this.allSelectableUsersSelected = this.isAllSelectableUsersSelected()
    }
  },

  methods: {
    onConfirm () {
      this.$emit('confirm', this.currentSelection)
    },

    onClose () {
      this.$emit('close')
    },

    onUserClicked (user) {
      user.selected = !user.selected

      if (user.selected) {
        this.currentSelection.push(user)
      } else {
        const i = this.currentSelection.indexOf(user)
        if (i !== -1) {
          this.currentSelection.splice(i, 1)
        }
      }

      this.$emit('selection-changed', this.currentSelection)
    },

    onToggleSelectAll () {
      // Only remove selection for users in the selectable array
      for (let i = this.currentSelection.length - 1; i >= 0; i--) {
        const user = this.currentSelection[i]
        if (this.isInSelectable(user)) {
          this.currentSelection.splice(i, 1)
        }
      }

      const selected = this.selectableNotAlreadySelected.filter(user => user.selected)
      const allSelected = selected.length === this.selectableNotAlreadySelected.length

      this.selectableNotAlreadySelected.forEach(user => {
        user.selected = !allSelected
        if (user.selected) {
          this.currentSelection.push(user)
        }
      })

      this.$emit('selection-changed', this.currentSelection)
    },

    onSearchStarted () {
      this.users = null
      this.isSearching = true
      this.allSelectableUsersSelected = false
    },

    onSearchComplete (users) {
      this.users = users
      this.allSelectableUsersSelected = false
    },

    onSearchReset () {
      this.users = this.selectable
      this.isSearching = false
      this.allSelectableUsersSelected = this.isAllSelectableUsersSelected()
    },

    filterVisibleUsers (user) {
      const i = this.selected.findIndex(selectedUser => user.id !== null ? user.id === selectedUser.id : user.email === selectedUser.email)
      return i === -1
    },

    sortUsersByName (a, b) {
      return a.getFullName().localeCompare(b.getFullName())
    },

    isAllSelectableUsersSelected () {
      if (this.selectable === null || this.selected === null || this.visibleUsers === null) return false

      // Only perform check if client actually provided a list
      if (this.selectable.length > 0) {
        const matches = this.selectable.filter(selectableUser => {
          return this.selected.findIndex(selectedUser => selectableUser.id === selectedUser.id || (selectedUser.email.length > 0 && selectableUser.email === selectedUser.email)) !== -1
        })

        const allSelected = matches.length === this.selectable.length
        return allSelected && this.visibleUsers.length === 0
      }

      return false
    },

    isInSelectable (user) {
      return this.selectable.findIndex(selectableUser => user.id === selectableUser.id || (selectableUser.email.length > 0 && user.email === selectableUser.email)) !== -1
    }
  }
}
</script>
