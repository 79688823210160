<template>
  <Wrapper v-if="report !== null">
    <Titlebar :title="$t('report.huntingReport')" />

    <Form class="default-spacing">
      <div class="box">
        <div class="columns">
          <div class="column is-half">
            <div class="columns">
              <div class="column is-half">
                <FormField :label="$t('report.title')">
                  <TextInputField
                    v-model="title"
                    :placeholder="$t('report.titlePlaceholder')"
                    :disabled="!canEdit">
                  </TextInputField>
                </FormField>
              </div>
            </div>

            <div class="columns">
              <div class="column is-half">
                <FormField :label="$t('report.startedAt')" mandatory>
                  <DatePicker
                    v-model="startsAt"
                    :placeholder="$t('report.startedAtPlaceholder')"
                    :disabled="!canEdit">
                  </DatePicker>

                  <HelpText
                    v-if="validation.startsAt === false"
                    type="danger"
                    :text="$t('report.startedAtRequired')">
                  </HelpText>
                </FormField>
              </div>

              <div class="column is-half">
                <FormField :label="$t('report.endedAt')" mandatory>
                  <DatePicker
                    v-model="endsAt"
                    :disabled="!canEdit"
                    :placeholder="$t('report.endedAtPlaceholder')">
                  </DatePicker>

                  <HelpText
                    v-if="validation.endsAt === false"
                    type="danger"
                    :text="$t('report.endedAtRequired')">
                  </HelpText>
                </FormField>
              </div>
            </div>

            <ReportEditPageParticipants />

            <Divider />

            <ReportEditPageEvents />
          </div>

          <div class="column is-half">
            <FormField :label="$t('report.manHours')">
              <NumberInputField
                v-model="hours"
                :disabled="calculateHours || !canEdit"
                :min="0">
              </NumberInputField>
            </FormField>

            <FormField>
              <Checkbox
                v-model="calculateHours"
                :label="$t('report.manHoursAutomaticCalculation')"
                :disabled="!canEdit">
              </Checkbox>
            </FormField>

            <FormField :label="$t('report.comment')">
              <TextArea
                v-model="comment"
                :rows="4"
                :disabled="!canEdit">
              </TextArea>
            </FormField>
          </div>
        </div>

        <Divider />

        <ReportEditPageToolbar
          :can-edit="canEdit"
          @on-close="onClose">
        </ReportEditPageToolbar>
      </div>
    </Form>
  </Wrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapProperty } from '@/common/utils/vuexHelpers'

import Titlebar from '@/modules/core/components/layout/Titlebar.vue'

import ReportEditPageParticipants from './ReportEditPageParticipants.vue'
import ReportEditPageEvents from './ReportEditPageEvents.vue'
import ReportEditPageToolbar from './ReportEditPageToolbar.vue'

export default {
  components: {
    Titlebar,

    ReportEditPageParticipants,
    ReportEditPageEvents,
    ReportEditPageToolbar
  },

  data () {
    return {
      reportId: this.$route.params.reportId,
      huntId: this.$route.query.huntId,
      prevRoute: null
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      userId: 'auth/getUserId',
      report: 'report/getReport',
      hunt: 'report/getHunt',
      events: 'report/events/getAll',
      credentials: 'auth/getBase64',
      validation: 'report/getValidation'
    }),

    title: mapProperty('report', 'getTitle', 'setTitle'),
    comment: mapProperty('report', 'getComment', 'setComment'),
    startsAt: mapProperty('report', 'getStartsAt', 'setStartsAt'),
    endsAt: mapProperty('report', 'getEndsAt', 'setEndsAt'),
    hours: mapProperty('report', 'getHours', 'setHours'),
    calculateHours: mapProperty('report', 'getCalculateHours', 'setCalculateHours'),

    isEditing () {
      return this.reportId !== undefined
    },

    canEdit () {
      return this.isAdmin || this.isHuntLeader
    },

    isAdmin () {
      return this.report.createdBy === this.userId
    },

    isHuntLeader () {
      return this.hunt !== null ? this.hunt.huntLeaderUserId === this.userId : false
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  created () {
    if (this.reportId) {
      this.open({
        reportId: this.reportId,
        huntId: this.huntId
      })
    } else {
      this.new(this.huntArea.id)
    }
  },

  methods: {
    ...mapActions({
      open: 'report/open',
      new: 'report/new'
    }),

    onClose () {
      this.$router.push(this.prevRoute)
    }
  }
}
</script>

<style scoped>
.default-spacing {
  padding-top: 0 !important;
}
</style>
