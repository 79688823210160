<template>
  <div>
    <FormField :label="$t('report.participants.title')">
      <Button
        v-if="canAddParticipant"
        icon="icon-plus-circle"
        :title="$t('report.participants.add')"
        @click="onClick">
      </Button>
    </FormField>

    <InlineLoader v-if="isLoading" />

    <div v-else>
      <ReportParticipantList
        display="grid"
        :loading="isLoading"
        :participants="visibleParticipants"
        :editable="true"
        @remove="onRemove">
      </ReportParticipantList>

      <div class="show-all">
        <a
          v-if="participants.length > 6 && !showAll"
          @click="onShowAll">
          {{ $t('report.participants.showAll') }}
        </a>
      </div>
    </div>

    <ReportAddParticipantsDialog
      v-if="participantsDialogVisible"
      :participants="participants"
      @close="participantsDialogVisible = false">
    </ReportAddParticipantsDialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import ReportParticipantList from '../shared/ReportParticipantList.vue'
import ReportAddParticipantsDialog from './dialogs/ReportAddParticipantsDialog.vue'

export default {
  components: {
    ReportParticipantList,
    ReportAddParticipantsDialog
  },

  data () {
    return {
      participantsDialogVisible: false,
      showAll: false
    }
  },

  computed: {
    ...mapGetters({
      userId: 'auth/getUserId',
      report: 'report/getReport',
      hunt: 'report/getHunt',
      participants: 'report/participants/getAll'
    }),

    isLoading () {
      return this.participants === null
    },

    canAddParticipant () {
      if (this.isLoading) return false

      const isHuntLeader = this.hunt !== null ? this.hunt.huntLeaderUserId === this.userId : false
      const isOwner = this.report.createdBy === this.userId

      return isOwner || isHuntLeader
    },

    visibleParticipants () {
      const sortByName = (a, b) => {
        return a.name.localeCompare(b.name)
      }

      const participants = this.showAll ? this.participants : this.participants.slice(0, 6)
      return participants.slice().sort(sortByName)
    }
  },

  methods: {
    ...mapMutations({
      removeParticipant: 'report/participants/remove',
      removeParticipantFromEvents: 'report/events/removeParticipant'
    }),

    onClick () {
      this.participantsDialogVisible = true
    },

    onRemove (participant) {
      this.removeParticipant(participant)
      this.removeParticipantFromEvents(participant)
    },

    onShowAll () {
      this.showAll = true
    }
  }
}
</script>

<style scoped>
.show-all {
  margin-top: 1rem;
}
</style>
