<template>
  <Table>
    <ReportEventDialogGameTableRow
      v-for="(game, i) in event.games"
      :key="i"
      :event="event"
      :game="game"
      @remove-game="onRemoveGame"
      @game-count-changed="onGameCountChanged">
    </ReportEventDialogGameTableRow>
  </Table>
</template>

<script>
import ReportEventDialogGameTableRow from './ReportEventDialogGameTableRow.vue'

export default {
  components: {
    ReportEventDialogGameTableRow
  },

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  methods: {
    onRemoveGame (game) {
      this.$emit('remove-game', game)
    },

    onGameCountChanged (game) {
      this.$emit('game-count-changed', game)
    }
  }
}
</script>
