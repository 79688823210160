<template>
  <Dialog :title="title" :buttons="buttons" @close="onCancel">
    <ReportEventDialogMap :event="event" />

    <FormField :label="$t('report.location')" :control="false">
      <ReportEventDialogLocation :event="event" />

      <HelpText
        v-if="missingCoordinate"
        type="danger"
        :text="$t('report.locationError')">
      </HelpText>
    </FormField>

    <FormField :label="$t('report.game.title')">
      <InlineLoader v-if="games === null" />

      <Dropdown v-else-if="games.length > 0" :text="$t('report.game.add')">
        <DropdownItem v-for="game in games" :key="game.id" @click="onAddGame(game)">
          {{ game.name }}
        </DropdownItem>
      </Dropdown>

      <Alert
        v-else
        type="danger"
        :message="$t('report.game.none')">
      </Alert>
    </FormField>

    <ReportEventDialogViltrapportAlert
      v-for="(game, i) in removedGamesInViltrapport"
      :key="i"
      :game="game"
      :removed-games="removedGames"
      @undo-remove-game="onUndoRemoveGame">
    </ReportEventDialogViltrapportAlert>

    <ReportEventDialogGameTable
      v-if="event.games.length > 0"
      :event="event"
      @remove-game="onRemoveGame"
      @game-count-changed="onGameCountChanged">
    </ReportEventDialogGameTable>

    <HelpText
      v-if="noGamesError"
      type="danger"
      :text="$t('report.game.error')">
    </HelpText>

    <FormField :label="$t('report.dateAndTime')">
      <DatePicker v-model="event.timestamp" :mode="dateMode" />

      <HelpText
        v-if="missingTimestamp"
        type="danger"
        :text="$t('report.dateError')">
      </HelpText>

      <Checkbox2
        v-model="event.useTimeInTimestamp"
        :label="$t('report.enterTime')">
      </Checkbox2>
    </FormField>

    <FormField :label="hunterTitle">
      <ReportEventDialogShooterDropdown :event="event" />
    </FormField>

    <FormField :label="$t('report.comment')">
      <TextArea
        v-model="event.comment"
        :rows="4">
      </TextArea>
    </FormField>

    <Divider />

    <ReportEventDialogAccordion v-if="canSendToViltrapport" :title="$t('report.sendToViltrapport')" :subtitle="viltrapportSectionSubtitle">
      <div v-for="(game, i) in event.games" :key="i">
        <ReportEventDialogViltrapportReport
          v-for="(viltrapportReport, j) in game.viltrapportReports"
          :key="j"
          :game="game"
          :report="viltrapportReport">
        </ReportEventDialogViltrapportReport>
      </div>
    </ReportEventDialogAccordion>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ViltrapportService from '@/modules/huntArea/viltrapport/services/viltrapportService.js'

import HuntReportEventModel from '../../../models/huntReportEventModel'
import HuntReportEventGameModel from '../../../models/huntReportEventGameModel'
import ViltrapportReportModel from '@/modules/huntArea/viltrapport/models/viltrapportReportModel'

import Dialog from '@/components/ui/dialog2/Dialog2.vue'

import ReportEventDialogAccordion from './ReportEventDialogAccordion.vue'
import ReportEventDialogMap from './ReportEventDialogMap.vue'
import ReportEventDialogLocation from './ReportEventDialogLocation'
import ReportEventDialogShooterDropdown from './ReportEventDialogShooterDropdown.vue'
import ReportEventDialogGameTable from './ReportEventDialogGameTable.vue'
import ReportEventDialogViltrapportAlert from './ReportEventDialogViltrapportAlert.vue'
import ReportEventDialogViltrapportReport from './ReportEventDialogViltrapportReport.vue'

export default {
  components: {
    Dialog,
    ReportEventDialogAccordion,
    ReportEventDialogMap,
    ReportEventDialogLocation,
    ReportEventDialogShooterDropdown,
    ReportEventDialogGameTable,
    ReportEventDialogViltrapportReport,
    ReportEventDialogViltrapportAlert
  },

  props: {
    selectedEvent: {
      type: Object,
      default: null
    },

    operation: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      event: new HuntReportEventModel(this.selectedEvent),
      removedGames: [],

      missingCoordinate: false,
      missingTimestamp: false,
      invalidGames: false,
      noGamesError: false
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      startsAt: 'report/getStartsAt',
      isConnectedToViltrapport: 'huntarea/viltrapport/isConnected',
      districts: 'huntarea/viltrapport/getDistricts',
      credentials: 'auth/getBase64'
    }),

    buttons () {
      return [
        {
          title: this.$i18n.t('general.cancel'),
          click: this.onCancel
        },
        {
          type: 'primary',
          title: this.operation === 'add' ? this.$i18n.t('general.add') : this.$i18n.t('general.save'),
          click: this.onSave
        }
      ]
    },

    title () {
      if (this.operation === 'add') {
        return this.event.type === 'kill' ? this.$i18n.t('report.events.addKill') : this.$i18n.t('report.events.addObservation')
      } else {
        const strings = {
          kill: this.$i18n.t('report.events.editKill'),
          observation: this.$i18n.t('report.events.editObservation'),
          after_search: this.$i18n.t('report.events.editAfterSearch')
        }

        return strings[this.event.type]
      }
    },

    districtSegments () {
      const districts = this.districts || []

      const segments = []

      districts.forEach(district => {
        segments.push({
          title: district.name,
          value: district.id
        })
      })

      return segments
    },

    hunterTitle () {
      return this.event.type === 'kill' ? this.$i18n.t('report.events.shooter') : this.$i18n.t('report.events.observer')
    },

    canSendToViltrapport () {
      return this.isConnectedToViltrapport && this.event.type === 'kill' && this.event.games.length > 0
    },

    removedGamesInViltrapport () {
      const gamesWithCountMismatch = this.event.games.filter(game => {
        const sentReports = game.viltrapportReports.filter(report => report.id !== null)
        return game.count < sentReports.length
      })

      const removedGamesSentToViltrapport = this.removedGames.filter(game => {
        for (const viltrapportReport of game.viltrapportReports) {
          if (viltrapportReport.id !== null) {
            return true
          }
        }
        return false
      })

      return [...gamesWithCountMismatch, ...removedGamesSentToViltrapport]
    },

    games () {
      let games = this.$store.getters['report/games/getAll']

      if (games !== null) {
        games = games.filter(game => this.event.games.findIndex(eventGame => eventGame.animalId === game.id) === -1)
        const language = this.$store.getters['ui/language']
        return games.slice(0).sort((a, b) => a.name.localeCompare(b.name, language, { sensitivity: 'base' }))
      }
      return null
    },

    viltrapportSectionSubtitle () {
      let totalGameCount = 0
      let totalViltrapportReports = 0

      this.event.games.forEach(game => {
        totalGameCount += game.count

        game.viltrapportReports.forEach(report => {
          if (report.selected) {
            totalViltrapportReports += 1
          }
        })
      })

      return `${totalViltrapportReports} / ${totalGameCount}`
    },

    timestamp () {
      return this.event.timestamp
    },

    coordinate () {
      return this.event.coordinate
    },

    dateMode () {
      return this.event.useTimeInTimestamp ? 'dateTime' : 'date'
    }
  },

  watch: {
    timestamp () {
      this.missingTimestamp = false
    },

    coordinate () {
      this.missingCoordinate = false
    }
  },

  created () {
    if (this.operation === 'add') {
      this.setDefaultTimestamp()
    }

    if (this.isConnectedToViltrapport) {
      this.readViltrapportGames()
      this.readViltrapportReports()
    }

    this.readGames()
  },

  methods: {
    ...mapActions({
      readViltrapportGames: 'huntarea/viltrapport/getGames',
      readGames: 'report/games/read'
    }),

    onSave () {
      if (this.validate()) {
        this.event.dirty = true // Make sure it's included when saving
        this.$emit('save', this.event)
      }
    },

    onCancel () {
      this.$emit('cancel')
    },

    validate () {
      this.missingTimestamp = this.event.timestamp === null
      this.missingCoordinate = this.event.coordinate === null
      this.noGamesError = this.event.games.length === 0

      const validGames = this.validateGames()

      return this.missingCoordinate === false && this.missingTimestamp === false && this.noGamesError === false && validGames
    },

    validateGames () {
      let valid = true

      for (const game of this.event.games) {
        if (!this.validateGameCount(game) || !this.validateViltrapporReports(game)) {
          valid = false
        }
      }

      return valid
    },

    validateGameCount (game) {
      game.invalid = game.count.valueOf() === 0
      return !game.invalid
    },

    validateViltrapporReports (game) {
      let valid = true

      for (const viltrapportReport of game.viltrapportReports) {
        viltrapportReport.invalid = viltrapportReport.selected && viltrapportReport.type === null

        if (viltrapportReport.invalid) {
          valid = false
        }
      }

      return valid
    },

    onAddGame (game) {
      const eventGame = new HuntReportEventGameModel({
        animalId: game.id,
        animalName: game.name,
        count: 1
      })

      if (this.isConnectedToViltrapport) {
        this.updateViltrapportReports(eventGame)
      }

      this.event.addGame(eventGame)
      this.noGamesError = false
    },

    onRemoveGame (game) {
      this.removedGames.push(game)
    },

    onGameCountChanged (eventGame) {
      if (this.isConnectedToViltrapport) {
        this.updateViltrapportReports(eventGame)
      }
    },

    updateViltrapportReports (eventGame) {
      const viltrapportGame = this.findViltrapportGameByName(eventGame.animalName)

      eventGame.viltrapportReports = eventGame.viltrapportReports.filter(report => report.id !== null)

      for (let i = eventGame.viltrapportReports.length; i < eventGame.count; i++) {
        const viltrapportReport = new ViltrapportReportModel({})

        if (viltrapportGame !== null) { // Will be null if Viltrapport does not have a matching animal
          viltrapportReport.speciesId = viltrapportGame.speciesId
          viltrapportReport.name = viltrapportGame.name

          if (viltrapportGame.types.length === 1) {
            viltrapportReport.type = viltrapportGame.types[0]
          }
        }

        eventGame.viltrapportReports.push(viltrapportReport)
      }
    },

    findViltrapportGameByName (name) {
      const games = this.$store.getters['huntarea/viltrapport/getGames'] || []

      for (const game of games) {
        if (game.name.toLowerCase() === name.toLowerCase()) {
          return game
        }
      }
      return null
    },

    setDefaultTimestamp () {
      this.event.timestamp = this.startsAt ? this.startsAt : new Date()
    },

    onUndoRemoveGame (game) {
      const i = this.removedGames.indexOf(game)
      this.removedGames.splice(i, 1)

      this.event.addGame(game)
    },

    async readViltrapportReports () {
      const viltrapport = new ViltrapportService(this.credentials)

      for (const game of this.event.games) {
        game.viltrapportReports = await viltrapport.getReports(this.huntArea.id, game.id)
        this.updateViltrapportReports(game)
      }
    }
  }
}
</script>
