<template>
  <div class="report-event-dialog-viltrapport-report" :class="{ 'is-invalid': report.invalid }">
    <div class="level is-mobile">
      <div class="level-left">
        <strong>
          {{ game.animalName }}
        </strong>
      </div>

      <div class="level-right">
        <Badge
          v-if="report.id !== null"
          type="success"
          :text="$t('report.game.sent')">
        </Badge>

        <input
          v-else
          v-model="report.selected"
          type="checkbox" />
      </div>
    </div>

    <div v-if="report.selected">
      <div class="level is-mobile">
        <div class="level-left">
          {{ $t('report.game.title') }}
        </div>

        <div class="level-right">
          <Dropdown
            v-if="report.id === null"
            alignment="right"
            :text="speciesName">
            <DropdownItem
              v-for="(g, i) in games"
              :key="i"
              @click="selectGame(g)">
              {{ g.name }}
            </DropdownItem>
          </Dropdown>

          <span v-else>{{ report.name }}</span>
        </div>
      </div>

      <div v-if="speciesTypes.length > 1" class="level is-mobile">
        <div class="level-left">
          <span v-if="report.id === null">
            {{ $t('report.game.typeSelect') }} <span class="has-text-danger">*</span>
          </span>

          <span v-else>{{ $t('report.game.type') }}</span>
        </div>

        <div class="level-right">
          <Dropdown
            v-if="report.id === null"
            alignment="right"
            :text="speciesTypeName">
            <DropdownItem
              v-for="(type, i) in speciesTypes"
              :key="i"
              @click="selectType(type)">
              {{ type.name }}
            </DropdownItem>
          </Dropdown>

          <span v-else>{{ report.type.name }}</span>
        </div>
      </div>

      <Divider />

      <div class="level is-mobile">
        <TextInputField
          v-if="report.id === null"
          v-model="report.comment"
          :placeholder="$t('report.comment')">
        </TextInputField>

        <div v-else-if="report.comment.length > 0" class="is-preformatted">{{ report.comment }}</div>
        <span v-else class="has-text-grey">{{ $t('report.commentNone') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    game: {
      type: Object,
      required: true
    },

    report: {
      type: Object,
      required: true
    }
  },

  computed: {
    games () {
      const games = this.$store.getters['huntarea/viltrapport/getGames'] || []

      if (games !== null) {
        const language = this.$store.getters['ui/language']
        return games.slice(0).sort((a, b) => a.name.localeCompare(b.name, language, { sensitivity: 'base' }))
      }

      return games
    },

    speciesTypes () {
      if (this.report.speciesId !== null) {
        const i = this.games.findIndex(game => game.speciesId === this.report.speciesId)
        return i !== -1 ? this.games[i].types : []
      }
      return []
    },

    speciesName () {
      return this.report.name !== null ? this.report.name : this.$i18n.t('general.select')
    },

    speciesTypeName () {
      return this.report.type !== null ? this.report.type.name : this.$i18n.t('general.select')
    }
  },

  methods: {
    selectGame (game) {
      this.report.speciesId = game.speciesId
      this.report.name = game.name
      this.report.type = null
    },

    selectType (speciesType) {
      this.report.type = speciesType
    }
  }
}
</script>

<style lang="scss" scoped>
.report-event-dialog-viltrapport-report {
  border: 1px solid lightgrey;
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;

  &.is-invalid {
    border: 1px solid red !important;
  }
}
</style>
