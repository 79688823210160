<template>
  <div>
    <Toolbar class="toolbar">
      <ToolbarItemGroup align="left">
        <ToolbarItem>
          <Button
            type="primary"
            :title="$t('general.save')"
            :loading="submitting"
            @click="onSubmit">
          </Button>
        </ToolbarItem>

        <ToolbarItem>
          <Button
            :title="$t('general.cancel')"
            @click="onCancel">
          </Button>
        </ToolbarItem>
      </ToolbarItemGroup>

      <ToolbarItemGroup v-if="canEdit" align="right">
        <ToolbarItem>
          <Button
            v-if="reportId"
            type="danger"
            icon="icon-trash"
            :loading="waitingForRemoval"
            @click="onRemove">
          </Button>
        </ToolbarItem>
      </ToolbarItemGroup>
    </Toolbar>

    <ReportRemoveConfirmDialog
      v-if="showRemoveDialog"
      :sent-to-viltrapport="isSentToViltrapport"
      @close="showRemoveDialog = false">
    </ReportRemoveConfirmDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ViltrapportService from '@/modules/huntArea/viltrapport/services/viltrapportService.js'

import ReportRemoveConfirmDialog from './dialogs/ReportRemoveConfirmDialog.vue'

export default {
  components: {
    ReportRemoveConfirmDialog
  },

  props: {
    canEdit: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      reportId: this.$route.params.reportId,

      submitting: false,
      waitingForRemoval: false,
      isSentToViltrapport: false,
      showRemoveDialog: false
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'report/getHuntArea',
      credentials: 'auth/getBase64',
      events: 'report/events/getAll',
      startsAt: 'report/getStartsAt',
      endsAt: 'report/getEndsAt',
      isConnectedToViltrapport: 'huntarea/viltrapport/isConnected'
    })
  },

  methods: {
    ...mapActions({
      save: 'report/save',
      validate: 'report/validate'
    }),

    async onSubmit () {
      try {
        const valid = await this.validate()
        if (valid) {
          this.submitting = true
          await this.save()

          this.onCancel()
        }
      } catch (error) {
        this.submitting = false
        this.$notification.danger(this.$i18n.t('general.saveFailedErrorMessage'))
        console.error(error)
      }
    },

    onCancel () {
      this.$emit('on-close')
    },

    async onRemove () {
      if (this.isConnectedToViltrapport) {
        this.waitingForRemoval = true
        this.isSentToViltrapport = await this.hasGamesSentToViltrapport()
        this.waitingForRemoval = false
      }

      this.showRemoveDialog = true
    },

    async hasGamesSentToViltrapport () {
      const viltrapportService = new ViltrapportService(this.credentials)

      for (const event of this.events) {
        for (const game of event.games) {
          const viltrapportReports = await viltrapportService.getReports(this.huntArea.id, game.id)
          if (viltrapportReports.length > 0) {
            return true
          }
        }
      }

      return false
    }
  }
}
</script>

<style scoped>
.toolbar {
  margin-bottom: 0;
}
</style>
