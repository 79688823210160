<template>
  <div>
    <FormField :label="$t('report.events.title')">
      <ButtonGroup v-if="!isLoading">
        <Button
          icon="icon-crosshair"
          :title="$t('report.events.addKill')"
          @click="onAddKill">
        </Button>

        <Button
          icon="icon-eye"
          :title="$t('report.events.addObservation')"
          @click="onAddObservation">
        </Button>
      </ButtonGroup>
    </FormField>

    <InlineLoader v-if="isLoading" />

    <ReportEventList
      v-else-if="events.length > 0"
      :events="events"
      :editable="true"
      @edit="onEdit"
      @remove="onRemove">
    </ReportEventList>

    <ReportEventDialog
      v-if="isEditing"
      :selected-event="event"
      :operation="operation"
      @save="onSave"
      @cancel="event = null">
    </ReportEventDialog>

    <ReportRemoveEventConfirmDialog
      v-if="isRemoving"
      :event="event"
      :sent-to-viltrapport="sentToViltrapport"
      @close="event = null">
    </ReportRemoveEventConfirmDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HuntReportEventModel from '../../models/huntReportEventModel'
import ViltrapportService from '@/modules/huntArea/viltrapport/services/viltrapportService.js'

import ReportEventList from '../shared/ReportEventList.vue'
import ReportEventDialog from './dialogs/ReportEventDialog.vue'
import ReportRemoveEventConfirmDialog from './dialogs/ReportRemoveEventConfirmDialog.vue'

export default {
  components: {
    ReportEventList,
    ReportEventDialog,
    ReportRemoveEventConfirmDialog
  },

  data () {
    return {
      event: null, // Event selected for edit or about to be created
      operation: null, // How the edit dialog will behave,
      sentToViltrapport: false // Whether or not the event being removed has game sent to Viltrapport
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      events: 'report/events/getAll',
      userId: 'auth/getUserId',
      credentials: 'auth/getBase64',
      isConnectedToViltrapport: 'huntarea/viltrapport/isConnected'
    }),

    isLoading () {
      return this.events === null
    },

    isEditing () {
      return this.event !== null && this.operation !== 'remove'
    },

    isRemoving () {
      return this.event !== null && this.operation === 'remove'
    }
  },

  methods: {
    onAddKill () {
      this.event = new HuntReportEventModel({
        type: 'kill',
        createdBy: this.userId
      })

      this.operation = 'add'
    },

    onAddObservation () {
      this.event = new HuntReportEventModel({
        type: 'observation',
        createdBy: this.userId
      })

      this.operation = 'add'
    },

    onEdit (event) {
      this.event = event
      this.operation = 'edit'
    },

    onSave (event) {
      if (this.operation === 'edit') {
        if (event.id !== null) {
          this.$store.commit('report/events/update', event)
        } else {
          this.$store.commit('report/events/remove', this.event)
          this.$store.commit('report/events/add', event)
        }
      } else {
        this.$store.commit('report/events/add', event)
      }

      this.event = null
    },

    async onRemove (event) {
      if (event.id !== null && this.isConnectedToViltrapport) {
        event.removing = true
        this.sentToViltrapport = await this.hasGamesSentToViltrapport(event)
        event.removing = false
      }

      this.event = event
      this.operation = 'remove'
    },

    async hasGamesSentToViltrapport (event) {
      const viltrapportService = new ViltrapportService(this.credentials)

      for (const game of event.games) {
        const viltrapportReports = await viltrapportService.getReports(this.huntArea.id, game.id)
        if (viltrapportReports.length > 0) {
          return true
        }
      }

      return false
    }
  }
}
</script>
