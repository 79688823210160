<template>
  <Map class="map" :noMaximize="true" @click="onMapClick">
    <MapBoundary
      v-if="boundary !== null"
      :coordinates="boundary.coordinates">
    </MapBoundary>

    <ReportEventDialogMapCoordinateMarker
      v-if="event.coordinate !== null"
      :coordinate="event.coordinate"
      :type="event.type">
    </ReportEventDialogMapCoordinateMarker>

    <ReportEventDialogMapMarker
      v-for="marker in markers"
      :key="marker.id"
      :marker="marker"
      :event="event"
      @click="onMarkerClicked">
    </ReportEventDialogMapMarker>
  </Map>
</template>

<script>
import { mapGetters } from 'vuex'

import Map from '@/modules/map/components/Map.vue'
import MapBoundary from '@/modules/map/components/MapBoundary.vue'

import ReportEventDialogMapMarker from './ReportEventDialogMapMarker.vue'
import ReportEventDialogMapCoordinateMarker from './ReportEventDialogMapCoordinateMarker.vue'

export default {
  components: {
    Map,
    MapBoundary,
    ReportEventDialogMapMarker,
    ReportEventDialogMapCoordinateMarker
  },

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      boundary: 'map/boundary/boundary'
    }),

    markers () {
      return this.$store.getters['map/markers/markers'] || []
    }
  },

  methods: {
    onMapClick (coordinate) {
      this.event.coordinate = coordinate
      this.event.poiId = null
      this.event.poiName = null
      this.event.poiType = null
    },

    onMarkerClicked (marker) {
      this.event.coordinate = marker.location
      this.event.poiId = marker.id
      this.event.poiName = marker.name
      this.event.poiType = marker.type
    }
  }
}
</script>

<style scoped>
.map {
  height: 300px;
  margin-bottom: 1rem;
}
</style>
